import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            navbar: {
                home: "Home",
                process: "How it works",
                search: "Find a car",
                testimonials: "Testimonials",
                faq: "FAQ",
                contact: "Contact",
            },
            hero: {
                title: "Import your car with Autostirad",
                description: "Import your car from the European union now with 0% customs",
                autoScout: "Auto Scout",
                mobileDe: "Mobile.De"
            },
            welcome: {
                title: "Welcome to Autostirad",
                subtitle: "Your trusted luxury car dealer"
            },
            content: {
                paragraph1: "At Autostirad, we specialize in providing the finest selection of luxury cars. Our showroom features a diverse range of high-end vehicles to suit every taste.",
                paragraph2: "Explore our extensive inventory of luxury vehicles. From sports cars to sedans, we have something for everyone.",
                paragraph3: "Get in touch with us to learn more about our offerings and how we can help you find your dream car."
            },
            subscribe: {
                title: "Subscribe to Our Newsletter",
                subtitle: "Stay updated with the latest arrivals and special offers"
            },
            contact: {
                title: "Contact Us",
                subTitle: "For any inquiries about our offers or process, please contact us. We will respond promptly.",
                name: "Name",
                email: "Email",
                phone: "Phone Number",
                phoneInvalid: "Please enter a valid phone number.",
                message: "Message",
                submit: "Submit",
                successMessage: "Your message has been successfully sent!",
                errorMessage: "Failed to send your message, please try again."
            },
            process: {
                title: "4 steps how you could buy with us",
                steps: {
                    step1: {
                        title: "Find a Car and Submit PO",
                        description: "User finds a suitable car on mobile.de and submits a Purchase Order (PO)."
                    },
                    step2: {
                        title: "Order Confirmation",
                        description: "We receive the order, confirm with the user, and finalize the deal with the dealer."
                    },
                    step3: {
                        title: "Paperwork Completion",
                        description: "We handle all the complicated paperwork in Germany, including Tüv and Euro.1 certifications."
                    },
                    step4: {
                        title: "Shipping",
                        description: "We ship the car from Germany to Egypt."
                    }
                }
            },
            faq: {
                title: "Frequently Asked Questions",
                items: {
                    question1: {
                        question: "How can I find the best cars online in the German marketplace?",
                        answer: "You can browse through our online platform, where we offer a wide variety of the best cars available in the German market. Our platform is designed to provide detailed information about each car to help you make an informed decision."
                    },
                    question2: {
                        question: "How do I confirm my car order?",
                        answer: "Once you have selected your car, you can place an order through our platform. We will then confirm that we have received your order and proceed with the necessary steps to finalize the purchase."
                    },
                    question3: {
                        question: "What are the payment options available?",
                        answer: "We offer secure payment options for your convenience. Once you have confirmed your order, we will guide you through the payment process and ensure that all financial transactions are secure and verified."
                    },
                    question4: {
                        question: "What does the shipment and delivery process involve?",
                        answer: "After completing the purchase and payment, we handle the entire process of buying the vehicle, managing the paperwork, and shipping the car to Egypt. We ensure that the car reaches you safely and in a timely manner."
                    },
                    question5: {
                        question: "Are there any specific laws and regulations I need to be aware of when importing a car from Germany to Egypt?",
                        answer: "Yes, there are specific regulations and laws regarding car imports to Egypt. These include customs duties, taxes, and compliance with local vehicle standards. We provide assistance to ensure all legal requirements are met."
                    },
                    question6: {
                        question: "How long does the entire process take?",
                        answer: "The duration of the process can vary depending on various factors, including the specific car you are purchasing and the shipping logistics. Generally, it takes around 45 days from placing the order to receiving the car in Egypt."
                    },
                    question7: {
                        question: "Can I track my shipment?",
                        answer: "Yes, once your car has been shipped, we provide tracking information so you can monitor the progress of your shipment and know when to expect delivery."
                    },
                    question8: {
                        question: "What types of cars are available for import?",
                        answer: "We offer a wide range of cars from Germany, including sedans, SUVs, luxury cars, and electric vehicles. You can find the type of car that suits your needs and preferences on our platform."
                    },
                    question9: {
                        question: "Is there a warranty or return policy for imported cars?",
                        answer: "Imported cars come with a warranty as per the terms specified by the seller in Germany. We recommend reviewing the warranty details before finalizing the purchase. Return policies may vary and are subject to the terms agreed upon at the time of sale."
                    },
                    question10: {
                        question: "How do I sign the sales contract?",
                        answer: "Once you have chosen your car and made the payment, we will send you the sales contract for review and signing. This can be done electronically to streamline the process."
                    },
                    question11: {
                        question: "Are there any additional costs I should be aware of?",
                        answer: "In addition to the purchase price of the car, you should consider customs duties, taxes, shipping fees, and any additional costs related to compliance with Egyptian import regulations."
                    },
                    question12: {
                        question: "What support do you provide after the car has been delivered?",
                        answer: "We offer post-delivery support to ensure your satisfaction with the car. This includes assistance with registration, maintenance advice, and addressing any issues that may arise."
                    }
                }
            },
            importRules: {
                title: "Import Rules and Regulations in Egypt",
                description: "Importing cars into Egypt involves several rules and regulations that need to be adhered to. Here are some key points:",
                points: [
                    "The car must be less than one year old at the time of importation.",
                    "A valid import license is required.",
                    "Customs duties and taxes must be paid based on the car's value and engine size.",
                    "Cars with right-hand drive are not allowed.",
                    "A technical inspection is required to ensure the car meets local standards."
                ]
            },
            carSearch: {
                title: "Search for Cars",
                make: "What brand are you looking for?",
                model: "Which model?",
                priceUpTo: "Price Up To",
                mileageUpTo: "Mileage Up To",
                searchButton: "Search",
                searchMobileDe: "Search on mobile.de",
                search: "Search",
                topMakes: "Top Makes",
                allMakes: "All Makes",
                price: "Price up to?",
                subTitle: "Get the best price without stress - receive your final sales price directly and order your car online"
            },
            footer: {
                privacy: "Privacy Policy",
                terms: "Terms of Service"
            },
            listingNotFound: {
                title: "Listing Not Found",
                description: "The vehicle listing you are looking for might have been removed or is unavailable.",
                findSimilar: "Find similar vehicles",
                newSearch: "Start a new search"
            },
            processSection: {
                title: "How it works in 4 simple steps",
                step1Title: "Find the best cars online",
                step1Description: "Choose from a variety of best cars in the German marketplace",
                step2Title: "We receive your order",
                step2Description: "We will confirm that we received your order in 24 hrs",
                step3Title: "Payment and confirmation",
                step3Description: "We sign a contract together and receive the down payment",
                step4Title: "Shipment and delivery",
                step4Description: "We take care of importing the vehicle, paperwork, and shipping to Egypt at your doorstep"
            },
            productDescription: {
                title: "Import your car from Germany in simple easy steps",
                description: "55,000+ cars at best prices | All checked & guaranteed | 45 days delivery time"
            },
            supportedBrands: {
                title: "Find our most popular cars"
            },
            searchResultsPage: {
                title: "Search Results",
                loadingMessage: "Looking for your perfect car ... it might take a minute",
                noCarsFound: "No cars found. Please try again with a different make, model, or price range.",
                startNewSearch: "Start a New Search",
                similarCars: "Similar Cars",
                errorFetchingListings: "Error fetching listing content",
                errorFetchingSimilarCars: "Error fetching similar cars"
            },
            vehicleDetailsPage: {
                mileage: "Mileage",
                gearbox: "Gearbox",
                fuelType: "Fuel Type",
                power: "Power",
                priceDetails: "Price Details",
                basePrice: "Base Price",
                vat: "VAT",
                customs: "Customs",
                shipping: "Shipping",
                finalPrice: "Final Price",
                loadingMessage: "Loading vehicle details...",
                backToVehicleList: "Back to vehicle list",
                basicData: "Basic Data",
                technicalSpecifications: "Technical Specifications",
                comfortConvenience: "Comfort & Convenience",
                entertainmentMedia: "Entertainment & Media",
                safetySecurity: "Safety & Security",
                extras: "Extras"
            }
        }
    },
    ar: {
        translation: {
            navbar: {
                home: "الصفحة الرئيسية",
                process: "كيف يعمل",
                search: "ابحث عن سيارة",
                testimonials: "الشهادات",
                faq: "الأسئلة الشائعة",
                contact: "اتصل بنا",
            },
            hero: {
                title: "استورد سيارتك مع أوتوستيراد",
                description: "استورد سيارتك من الاتحاد الأوروبي الآن مع إعفاء من الجمارك بنسبة 0%",
                autoScout: "أوتو سكاوت",
                mobileDe: "موبايل.دي"
            },
            welcome: {
                title: "مرحبًا بك في أوتوستيراد",
                subtitle: "وكيل السيارات الفاخرة الموثوق به"
            },
            content: {
                paragraph1: "في أوتوستيراد، نتميز بتقديم أفضل مجموعة من السيارات الفاخرة. صالة عرضنا تحتوي على مجموعة متنوعة من السيارات الراقية لتناسب كل الأذواق.",
                paragraph2: "استكشف مجموعتنا الواسعة من السيارات الفاخرة. من السيارات الرياضية إلى السيدان، لدينا شيء للجميع.",
                paragraph3: "تواصل معنا لمعرفة المزيد عن عروضنا وكيف يمكننا مساعدتك في العثور على سيارة أحلامك."
            },
            subscribe: {
                title: "اشترك في نشرتنا الإخبارية",
                subtitle: "ابقَ على اطلاع على أحدث العروض والسيارات"
            },
            contact: {
                title: "اتصل بنا",
                subTitle: "لأي استفسارات حول عروضنا، يرجى الاتصال بنا. سنرد عليك في أقرب وقت.",
                name: "الاسم",
                email: "البريد الإلكتروني",
                phone: "رقم الهاتف",
                phoneInvalid: "يرجى إدخال رقم هاتف صالح.",
                message: "رسالة",
                submit: "إرسال",
                successMessage: "تم إرسال رسالتك بنجاح!",
                errorMessage: "فشل في إرسال رسالتك، يرجى المحاولة مرة أخرى."
            },
            process: {
                title: "4 خطوات لشراء سيارتك معنا",
                steps: {
                    step1: {
                        title: "ابحث عن سيارة وأرسل طلب شراء",
                        description: "يبحث المستخدم عن سيارة مناسبة على mobile.de ويقدم طلب شراء."
                    },
                    step2: {
                        title: "تأكيد الطلب",
                        description: "نتلقى الطلب، نؤكده مع المستخدم، وننهي الصفقة مع الوكيل."
                    },
                    step3: {
                        title: "إتمام الأوراق",
                        description: "نتعامل مع جميع الأوراق المعقدة في ألمانيا، بما في ذلك شهادات TÜV وEuro.1."
                    },
                    step4: {
                        title: "الشحن",
                        description: "نقوم بشحن السيارة من ألمانيا إلى مصر."
                    }
                }
            },
            faq: {
                title: "الأسئلة الشائعة",
                items: {
                    question1: {
                        question: "كيف أجد أفضل السيارات عبر الإنترنت في السوق الألماني؟",
                        answer: "يمكنك التصفح عبر منصتنا الإلكترونية، حيث نقدم مجموعة واسعة من أفضل السيارات المتاحة في السوق الألماني. تم تصميم منصتنا لتزويدك بمعلومات مفصلة حول كل سيارة لمساعدتك في اتخاذ قرار مستنير."
                    },
                    question2: {
                        question: "كيف أؤكد طلب شراء سيارتي؟",
                        answer: "بمجرد اختيارك للسيارة، يمكنك تقديم الطلب من خلال منصتنا. ثم نؤكد استلام طلبك ونتخذ الخطوات اللازمة لإتمام الشراء."
                    },
                    question3: {
                        question: "ما هي خيارات الدفع المتاحة؟",
                        answer: "نقدم خيارات دفع آمنة لراحتك. بمجرد تأكيد طلبك، سنرشدك خلال عملية الدفع ونتأكد من أن جميع المعاملات المالية آمنة."
                    },
                    question4: {
                        question: "ماذا تتضمن عملية الشحن والتسليم؟",
                        answer: "بعد إتمام الشراء والدفع، نتولى عملية شراء السيارة وإدارة الأوراق وشحن السيارة إلى مصر. نحرص على وصول السيارة إليك بأمان وفي الوقت المحدد."
                    },
                    question5: {
                        question: "هل هناك قوانين ولوائح معينة يجب أن أكون على علم بها عند استيراد سيارة من ألمانيا إلى مصر؟",
                        answer: "نعم، هناك قوانين ولوائح خاصة تتعلق باستيراد السيارات إلى مصر، بما في ذلك الرسوم الجمركية والضرائب وامتثال السيارة للمعايير المحلية. نحن نقدم المساعدة لضمان تلبية جميع المتطلبات القانونية."
                    },
                    question6: {
                        question: "كم يستغرق الوقت لإتمام العملية بالكامل؟",
                        answer: "تختلف المدة حسب عدة عوامل، بما في ذلك السيارة المحددة التي تقوم بشرائها والخدمات اللوجستية للشحن. بشكل عام، تستغرق العملية حوالي 45 يومًا من تقديم الطلب إلى استلام السيارة في مصر."
                    },
                    question7: {
                        question: "هل يمكنني تتبع شحنتي؟",
                        answer: "نعم، بمجرد شحن سيارتك، نقدم لك معلومات التتبع حتى تتمكن من مراقبة تقدم الشحنة ومعرفة موعد الوصول المتوقع."
                    },
                    question8: {
                        question: "ما هي أنواع السيارات المتاحة للاستيراد؟",
                        answer: "نقدم مجموعة واسعة من السيارات من ألمانيا، بما في ذلك السيارات السيدان، سيارات الدفع الرباعي، السيارات الفاخرة، والسيارات الكهربائية. يمكنك العثور على النوع المناسب لاحتياجاتك."
                    },
                    question9: {
                        question: "هل هناك ضمان أو سياسة إرجاع للسيارات المستوردة؟",
                        answer: "تأتي السيارات المستوردة مع ضمان وفقًا للشروط المحددة من قبل البائع في ألمانيا. نوصي بمراجعة تفاصيل الضمان قبل إتمام الشراء. قد تختلف سياسات الإرجاع بناءً على الشروط المتفق عليها عند البيع."
                    },
                    question10: {
                        question: "كيف أوقع عقد البيع؟",
                        answer: "بمجرد اختيارك للسيارة وإتمام الدفع، سنرسل لك عقد البيع للمراجعة والتوقيع. يمكن القيام بذلك إلكترونيًا لتسهيل العملية."
                    },
                    question11: {
                        question: "هل هناك أي تكاليف إضافية يجب أن أكون على علم بها؟",
                        answer: "بالإضافة إلى سعر شراء السيارة، يجب أن تأخذ في الاعتبار الرسوم الجمركية والضرائب وتكاليف الشحن وأي تكاليف إضافية تتعلق بالامتثال للوائح الاستيراد المصرية."
                    },
                    question12: {
                        question: "ما هي الدعم الذي تقدمه بعد تسليم السيارة؟",
                        answer: "نقدم الدعم بعد التسليم لضمان رضاك عن السيارة. يشمل ذلك المساعدة في التسجيل، نصائح الصيانة، وحل أي مشكلات قد تنشأ."
                    }
                }
            },
            importRules: {
                title: "قواعد ولوائح الاستيراد في مصر",
                description: "يتضمن استيراد السيارات إلى مصر عدة قواعد ولوائح يجب الالتزام بها. إليك بعض النقاط الرئيسية:",
                points: [
                    "يجب أن تكون السيارة أقل من سنة واحدة عند الاستيراد.",
                    "يجب الحصول على رخصة استيراد سارية.",
                    "يجب دفع الرسوم الجمركية والضرائب بناءً على قيمة السيارة وحجم المحرك.",
                    "لا يُسمح بالسيارات ذات القيادة على الجانب الأيمن.",
                    "يتطلب إجراء فحص تقني للتأكد من مطابقة السيارة للمعايير المحلية."
                ]
            },
            carSearch: {
                title: "ابحث عن السيارات",
                make: "ما العلامة التجارية التي تبحث عنها؟",
                model: "ما هو الموديل؟",
                priceUpTo: "السعر حتى",
                mileageUpTo: "عدد الكيلومترات حتى",
                searchButton: "ابحث",
                searchMobileDe: "ابحث على mobile.de",
                search: "بحث",
                topMakes: "أفضل العلامات التجارية",
                allMakes: "كل العلامات التجارية",
                price: "السعر حتى؟",
                subTitle: "احصل على أفضل سعر بدون قلق - استلم السعر النهائي واطلب سيارتك عبر الإنترنت"
            },
            footer: {
                privacy: "سياسة الخصوصية",
                terms: "شروط الخدمة"
            },
            listingNotFound: {
                title: "القائمة غير موجودة",
                description: "القائمة التي تبحث عنها قد تم إزالتها أو غير متاحة.",
                findSimilar: "ابحث عن سيارات مماثلة",
                newSearch: "ابدأ بحث جديد"
            },
            processSection: {
                title: "كيف يعمل في 4 خطوات بسيطة",
                step1Title: "ابحث عن أفضل السيارات عبر الإنترنت",
                step1Description: "اختر من بين مجموعة متنوعة من أفضل السيارات في السوق الألماني",
                step2Title: "نتلقى طلبك",
                step2Description: "سوف نؤكد استلام طلبك خلال 24 ساعة",
                step3Title: "الدفع والتأكيد",
                step3Description: "نوقع عقدًا معًا ونتلقى الدفعة المقدمة",
                step4Title: "الشحن والتسليم",
                step4Description: "نتولى استيراد السيارة وإدارة الأوراق وشحنها إلى مصر حتى باب منزلك"
            },
            productDescription: {
                title: "استورد سيارتك من ألمانيا بخطوات بسيطة وسهلة",
                description: "أكثر من 55,000 سيارة بأفضل الأسعار | كلها مفحوصة ومضمونة | زمن تسليم 45 يومًا"
            },
            supportedBrands: {
                title: "اكتشف سياراتنا الأكثر شعبية"
            },
            searchResultsPage: {
                title: "نتائج البحث",
                loadingMessage: "نبحث عن السيارة المثالية لك ... قد يستغرق الأمر دقيقة",
                noCarsFound: "لم يتم العثور على سيارات. يرجى المحاولة مرة أخرى مع علامة تجارية أو موديل أو نطاق سعر مختلف.",
                startNewSearch: "ابدأ بحث جديد",
                similarCars: "سيارات مماثلة",
                errorFetchingListings: "خطأ في جلب محتويات القائمة",
                errorFetchingSimilarCars: "خطأ في جلب السيارات المماثلة"
            },
            vehicleDetailsPage: {
                mileage: "عدد الكيلومترات",
                gearbox: "ناقل الحركة",
                fuelType: "نوع الوقود",
                power: "القدرة",
                priceDetails: "تفاصيل السعر",
                basePrice: "السعر الأساسي",
                vat: "ضريبة القيمة المضافة",
                customs: "الجمارك",
                shipping: "الشحن",
                finalPrice: "السعر النهائي",
                loadingMessage: "جاري تحميل تفاصيل السيارة...",
                backToVehicleList: "العودة إلى قائمة السيارات",
                basicData: "البيانات الأساسية",
                technicalSpecifications: "المواصفات التقنية",
                comfortConvenience: "الراحة والملاءمة",
                entertainmentMedia: "الترفيه والوسائط",
                safetySecurity: "السلامة والأمان",
                extras: "الإضافات"
            }
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
