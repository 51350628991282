import React from 'react';
import './ProcessSection.css';

// Import SVGs
import { ReactComponent as CarSaleIcon } from '../icons/24h-car-sale-icon.svg';
import { ReactComponent as ContractIcon } from '../icons/car-sales-contract-icon.svg';
import { ReactComponent as OnlineCarIcon } from '../icons/free-online-car-assessment-icon.svg';
import { ReactComponent as PaymentIcon } from '../icons/secure-payment-blue-icon.svg';
import { useTranslation } from 'react-i18next';

const FeatureCard = () => {
    const { t } = useTranslation();

    return (
        <div className="feature-cards-container">
            <div className="feature-card">
                <OnlineCarIcon className="feature-logo" />
                <div className="feature-card-description-container">
                    <p className="feature-card-title">{t('processSection.step1Title')}</p>
                    <p>{t('processSection.step1Description')}</p>
                </div>
            </div>

            <div className="feature-card">
                <CarSaleIcon className="feature-logo" />
                <div className="feature-card-description-container">
                    <p className="feature-card-title">{t('processSection.step2Title')}</p>
                    <p>{t('processSection.step2Description')}</p>
                </div>
            </div>

            <div className="feature-card">
                <PaymentIcon className="feature-logo" />
                <div className="feature-card-description-container">
                    <p className="feature-card-title">{t('processSection.step3Title')}</p>
                    <p>{t('processSection.step3Description')}</p>
                </div>
            </div>

            <div className="feature-card">
                <ContractIcon className="feature-logo" />
                <div className="feature-card-description-container">
                    <p className="feature-card-title">{t('processSection.step4Title')}</p>
                    <p>{t('processSection.step4Description')}</p>
                </div>
            </div>
        </div>
    );
};

const ProcessSection = () => {
    const { t } = useTranslation();

    return (
        <div id="process">
            <p className="process-section-title">{t('processSection.title')}</p>
            <FeatureCard />
        </div>
    );
};

export default ProcessSection;
