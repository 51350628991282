// import carData from '../data/carData.json';
import carData from "../data/availableCarData";
// import makes from '../data/makes.json';
import makes from "../data/euMakes.json";

export const fetchMakes = async () => {
    try {
        return makes.makes.map(make => ({ id: make.i, name: make.n }))
    } catch (error) {
        console.error('Error fetching makes from local file.', error);
        return [];
    }
};

export const fetchModels = async (makeName) => {
    try {
        return carData[makeName] || [];
    } catch (error) {
        console.error(`Error fetching models for makeId ${makeName} from local file.`, error);
        return [];
    }
};
