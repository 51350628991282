import React from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SupportedBrands.css';

const brands = [
    { make: 'Audi', src: 'https://content.autohero.com/static/car_images/AUDI.webp', title: 'Audi', name: 'Audi' },
    { make: 'BMW', src: 'https://content.autohero.com/static/car_images/BMW.webp', title: 'BMW', name: 'BMW' },
    { make: 'Mercedes Benz', src: 'https://content.autohero.com/static/car_images/MERCEDES.webp', title: 'Mercedes Benz', name: 'Mercedes Benz' },
    { make: 'MINI', src: 'https://content.autohero.com/static/car_images/MINI.webp', title: 'Mini', name: 'Mini' },
    { make: 'Opel', src: 'https://content.autohero.com/static/car_images/OPEL.webp', title: 'Opel', name: 'Opel' },
    { make: 'Seat', src: 'https://content.autohero.com/static/car_images/SEAT.webp', title: 'Seat', name: 'Seat' },
    { make: 'Skoda', src: 'https://content.autohero.com/static/car_images/SKODA.webp', title: 'Skoda', name: 'Skoda' },
    { make: 'Volkswagen', src: 'https://content.autohero.com/static/car_images/VW.webp', title: 'Volkswagen', name: 'Volkswagen' },
];

const SupportedBrands = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAutoScout24Search = (make) => {
        const page = 1;
        const modelName = "";
        const price = "";
        const searchParams = {
            make,
            modelName,
            price,
            page
        };
        navigate(`/search-results?${new URLSearchParams(searchParams).toString()}`);
    };

    return (
        <div>
            <div className="supported-brands-title">
                {t('supportedBrands.title')}
                <Box sx={{ width: '100px', height: '4px', backgroundColor: '#F97316', margin: '0 auto 1rem', marginTop: '8px' }}></Box>
            </div>
            <Box className="supported-brands">
                <Grid container spacing={3}>
                    {brands.map((brand, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <Box
                                className="brand-logo"
                                onClick={() => handleAutoScout24Search(brand.make)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img src={brand.src} alt={brand.name} />
                                <span>{brand.name}</span>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};

export default SupportedBrands;
