import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Pagination, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './SearchResultsPage.css';

// Reusable Components

const CarIcon = () => <img src="/assets/mystery.png" alt="Car Icon" />;

const CarInfoSection = ({ power, mileage, fuelType, transmission }) => {
    const horsePower = power.hp;
    const kW = power.kW;
    return (
        <>
            {mileage} {"km"} {" • "} {transmission} {" • "} {fuelType} {" • "} {`${horsePower} hp (${kW} kW)`}
        </>
    );
};

const LoadingComponent = () => {
    const { t } = useTranslation();
    return (
        <Box className="loading-spinner">
            <CircularProgress />
            <Typography variant="body1">
                {t('searchResultsPage.loadingMessage')}
            </Typography>
        </Box>
    );
};

const ErrorMessage = ({ error }) => (
    <Typography variant="body1" color="error">
        {error}
    </Typography>
);

const CarListingMobile = ({ car, index, handleImageError, imageErrors, navigate }) => {
    const formattedPrice = new Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(car.price);

    return (
        <div className="car-listing" onClick={() => navigate(`/details?carUrl=${encodeURIComponent(car.link)}`)}>
            <div className="relative">
                {imageErrors[index] ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '230px' }}>
                        <CarIcon />
                    </div>
                ) : (
                    <img
                        src={car.image}
                        alt={`Car ${index}`}
                        className="rounded-t-lg w-full"
                        onError={() => handleImageError(index)}
                    />
                )}
            </div>
            <div className="search-item-details">
                <h2 className="text-sm car-title">{car.title.split("\n")[0]}</h2>
                <div className="car-subTitle">{car.title.split("\n")[1]}</div>
                <div className="car-price-section">
                    <h3 className="car-price-section-value">{formattedPrice}</h3>
                </div>
                <div className="car-details-section">
                    <CarInfoSection mileage={car.mileage} transmission={car.transmission} fuelType={car.fuelType} power={car.power} />
                </div>
            </div>
        </div>
    );
};

const CarListingDesktop = ({ car, index, handleImageError, imageErrors, navigate }) => {
    const formattedPrice = new Intl.NumberFormat('en-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(car.price);

    return (
        <div className="car-listing-desktop" onClick={() => navigate(`/details?carUrl=${encodeURIComponent(car.link)}`)}>
            <div className="car-image-desktop">
                {imageErrors[index] ? (
                    <div className="car-icon-placeholder">
                        <CarIcon />
                    </div>
                ) : (
                    <img
                        src={car.image}
                        alt={`Car ${index}`}
                        className="car-img-desktop"
                        onError={() => handleImageError(index)}
                    />
                )}
            </div>
            <div className="car-details-desktop">
                <h2 className="car-title-desktop">{car.title.split("\n")[0]}</h2>
                <div className="car-subTitle-desktop">{car.title.split("\n")[1]}</div>
                <div className="car-price-section-desktop">
                    <h3 className="car-price-desktop">{formattedPrice}</h3>
                </div>
                <div className="car-info-desktop">
                    <CarInfoSection mileage={car.mileage} transmission={car.transmission} fuelType={car.fuelType} power={car.power} />
                </div>
            </div>
        </div>
    );
};

// Responsive CarListing Component
const CarListing = ({ car, index, handleImageError, imageErrors, navigate }) => {
    const isMobile = useMediaQuery('(max-width: 767px)');

    return isMobile ? (
        <CarListingMobile car={car} index={index} handleImageError={handleImageError} imageErrors={imageErrors} navigate={navigate} />
    ) : (
        <CarListingDesktop car={car} index={index} handleImageError={handleImageError} imageErrors={imageErrors} navigate={navigate} />
    );
};

// Main Search Results Component
const AutoScout24SearchResults = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(search);
    const make = params.get('make');
    const modelName = params.get('modelName');
    const page = parseInt(params.get('page') || 1);
    const sort = params.get('sort') || ''; // Get the current sort option from query params

    const [carData, setCarData] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [similarCars, setSimilarCars] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageErrors, setImageErrors] = useState({});
    const [sortOption, setSortOption] = useState(sort); // Track selected sorting option
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Control visibility of options

    const pageSize = 20;
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    useEffect(() => {
        const fetchAutoScout24 = async () => {
            if (!make) return;

            setLoading(true);

            try {
                let apiUrlWithParams = `${apiUrl}/listing?make=${make}&modelName=${modelName}&page=${page}`;
                if (sortOption) {
                    apiUrlWithParams += `&sort=${sortOption}`; // Append sort option if it exists
                }

                const response = await fetch(apiUrlWithParams);
                if (!response.ok) throw new Error(t('searchResultsPage.errorFetchingListings'));

                const data = await response.json();
                setCarData(data.listings || []);
                setHasNextPage(data.hasNextPage || false);
                setTotalCount(data.totalCount || 0);

                if (data.listings.length === 0) fetchSimilarCars(make);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAutoScout24();
    }, [make, modelName, page, sortOption, apiUrl, t]);

    const fetchSimilarCars = async (make) => {
        try {
            const response = await fetch(`${apiUrl}/listing?make=${make}`);
            if (!response.ok) throw new Error(t('searchResultsPage.errorFetchingSimilarCars'));

            const data = await response.json();
            setSimilarCars(data.listings || []);
        } catch (err) {
            setError(err.message);
        }
    };

    const handlePageChange = (event, value) => {
        navigate(`/search-results?make=${make}&modelName=${modelName}&page=${value}&sort=${sortOption}`);
    };

    const handleSortChange = (selectedSort) => {
        setSortOption(selectedSort);
        setIsDropdownOpen(false); // Close dropdown after selection

        // Update the URL with the new sorting option
        if (selectedSort === '') {
            navigate(`/search-results?make=${make}&modelName=${modelName}&page=1`); // Best match (no sort param)
        } else {
            navigate(`/search-results?make=${make}&modelName=${modelName}&page=1&sort=${selectedSort}`);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle visibility of dropdown options
    };

    const handleNewSearch = () => {
        navigate('/#search');
    };

    const handleImageError = (index) => {
        setImageErrors(prevErrors => ({ ...prevErrors, [index]: true }));
    };

    return (
        <>
            {loading ? <LoadingComponent /> : <Box className="autoscout24-search-results">
                {error && <ErrorMessage error={error} />}
                {carData.length === 0 ? (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="body1" color="textSecondary">
                            {t('searchResultsPage.noCarsFound')}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNewSearch}
                            className="search-result-button"
                            style={{ background: "#f37121", color: "#fff", width: "300px", margin: 'auto', marginTop: '1rem' }}
                        >
                            {t('searchResultsPage.startNewSearch')}
                        </Button>
                        {similarCars.length > 0 && (
                            <>
                                <div className="similar-cars-title">{t('searchResultsPage.similarCars')}</div>
                                <div className="car-listings">
                                    {similarCars.map((car, index) => (
                                        <CarListing
                                            key={index}
                                            car={car}
                                            index={index}
                                            handleImageError={handleImageError}
                                            imageErrors={imageErrors}
                                            navigate={navigate}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </Box>
                ) : (
                    <>
                        <div className="search-result-page-title">
                            <div>
                                {new Intl.NumberFormat('en-US').format(totalCount)} offers for {make} {modelName}

                            </div>
                            <div>

                                <div className="SortDropdown_wrapper">
                                    <label htmlFor="sort-dropdown-select" className="SortDropdown_label">
                                        <span className="SortDropdown_title">Sort:&nbsp;</span>
                                        <span onClick={toggleDropdown} className="SortDropdown_link">
                                            {sortOption === '' ? 'Best results' : sortOption === 'priceAsc' ? 'Price ascending' : 'Price descending'}
                                            &nbsp;▼
                                        </span>
                                    </label>

                                    {isDropdownOpen && (
                                        <ul className="SortDropdown_options">
                                            <li onClick={() => handleSortChange('')}>Best results</li>
                                            <li onClick={() => handleSortChange('priceAsc')}>Price ascending</li>
                                            <li onClick={() => handleSortChange('priceDsc')}>Price descending</li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="car-listings">
                            {carData.map((car, index) => (
                                <CarListing
                                    key={index}
                                    car={car}
                                    index={index}
                                    handleImageError={handleImageError}
                                    imageErrors={imageErrors}
                                    navigate={navigate}
                                />
                            ))}
                        </div>
                    </>
                )}
                <Box className="pagination-bar" >
                    <Pagination
                        count={Math.ceil(totalCount / pageSize)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </Box>}
        </>
    );
};

export default AutoScout24SearchResults;
