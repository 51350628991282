import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactForm.css';

const ContactForm = () => {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [status, setStatus] = useState({ success: false, error: false, message: '' });
    const [errors, setErrors] = useState({ phone: '' });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'phone') {
            validatePhone(e.target.value);
        }
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[0-9]{10,15}$/;
        if (!phoneRegex.test(phone)) {
            setErrors({ ...errors, phone: t('contact.phoneInvalid') });
        } else {
            setErrors({ ...errors, phone: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (errors.phone) {
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setStatus({ success: true, error: false, message: t('contact.successMessage') });
                setFormData({ name: '', email: '', phone: '', message: '' });
            } else {
                setStatus({ success: false, error: true, message: t('contact.errorMessage') });
            }
        } catch (error) {
            console.error('Error:', error);
            setStatus({ success: false, error: true, message: t('contact.errorMessage') });
        }
    };

    return (
        <section id="contact" className="contact-section">
            <div className="contact-container">
                <p className="contact-form-title">{t('contact.title')}</p>
                <p>{t('contact.subTitle')}</p>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">{t('contact.name')}</label>
                        <input type="text" id="name" name="name" required value={formData.name} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">{t('contact.email')}</label>
                        <input type="email" id="email" name="email" required value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">{t('contact.phone')}</label>
                        <input type="tel" id="phone" name="phone" required value={formData.phone} onChange={handleChange} />
                        {errors.phone && <span className="error-message">{errors.phone}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">{t('contact.message')}</label>
                        <textarea id="message" name="message" rows="4" required value={formData.message} onChange={handleChange}></textarea>
                    </div>
                    <button type="submit" className="contact-button">{t('contact.submit')}</button>
                </form>
                {status.message && (
                    <div className={`message-box ${status.success ? 'success' : 'error'}`}>
                        {status.message}
                    </div>
                )}
            </div>
        </section>
    );
};

export default ContactForm;
