import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { ReactComponent as CarIcon } from '../icons/car.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './ListingNotFound.css';

const ListingNotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Box width="100%" backgroundColor="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4}>
                <CarIcon style={{ width: '100px', height: '100px' }} />
                <Typography variant="h4" color="textPrimary" style={{ marginTop: '20px' }}>
                    {t('listingNotFound.title')}
                </Typography>
                <Typography variant="body1" color="textSecondary" style={{ marginTop: '10px' }}>
                    {t('listingNotFound.description')}
                </Typography>
            </Box>

            <Box width="100%" backgroundColor="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={4}>
                <Grid container spacing={2} style={{ marginTop: '1rem', width: "45%", margin: 'auto' }}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={() => navigate(-1)}
                            style={{ background: "#f37121", color: "#fff" }}
                        >
                            {t('listingNotFound.findSimilar')}
                        </Button>
                        <span
                            className="listing-not-found-new-search"
                            onClick={() => navigate('/#search')}
                        >
                            {t('listingNotFound.newSearch')}
                        </span>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ListingNotFound;
