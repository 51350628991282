import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import ContactForm from './components/ContactForm';
import SupportedBrands from './components/SupportedBrands';
import ProcessSection from './components/ProcessSection';
import FAQSection from './components/FAQSection';
import CarSearchComponent from './components/CarSearchComponent';
import ProductDescription from './components/ProductDescription';
import SearchResultsPage from './pages/SearchResultsPage';
import VehicleDetailsPage from './pages/VehicleDetailsPage';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import './i18n';
import './App.css';

const App = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const theme = createTheme({
        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
    });

    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Suspense fallback="Loading...">
                    <Router>
                        <div className={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <Navbar onLanguageChange={changeLanguage} />
                            <Routes>
                                <Route path="/" element={
                                    <div id="home">
                                        <HeroSection />
                                        <ProductDescription />
                                        <CarSearchComponent />
                                        <ProcessSection />
                                        <div className="contact-brands-wrapper">
                                            <ContactForm />
                                            <SupportedBrands />
                                        </div>
                                        <FAQSection />
                                    </div>
                                } />
                                <Route path="/search-results" element={<SearchResultsPage />} />
                                <Route path="/details" element={<VehicleDetailsPage />} />
                            </Routes>
                        </div>
                    </Router>
                </Suspense>
            </ThemeProvider>
        </StylesProvider>
    );
};

export default App;
