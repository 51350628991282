import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import './Navbar.css';

const Navbar = ({ onLanguageChange }) => {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);


    const handleLanguageChange = () => {
        const lng = currentLanguage === 'ar' ? 'en' : 'ar'
        i18n.changeLanguage(lng);
        setCurrentLanguage(lng);
        onLanguageChange(lng);
    };

    useEffect(() => {
        document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    }, [i18n.language]);

    return (
        <>
            <nav className="navbar">
                <div className='navbar-logo'>
                    <Link to="/" className="navbar-brand">
                        <img src="/assets/Autosrtad_logo.png" alt="Autostirad Logo" />
                    </Link>
                </div>
                <div className='triangle-up'></div>
                <div className="navbar-container">
                    <div className="navbar-menu">
                        <ul className="navbar-nav">
                            <li><a href="#process">{t('navbar.process')}</a></li>
                            <li><a href="#search">{t('navbar.search')}</a></li>
                            <li><a href="#testimonials">{t('navbar.testimonials')}</a></li>
                            <li><a href="#faq">{t('navbar.faq')}</a></li>
                            <li><a href="#contact">{t('navbar.contact')}</a></li>
                            <li><Button
                                aria-controls="language-menu"
                                aria-haspopup="true"
                                onClick={() => handleLanguageChange()}
                            >
                                {currentLanguage === 'en' ? 'العربية' : 'English'}
                            </Button></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
