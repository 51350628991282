import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProductDescription.css';

const ProductDescription = () => {
    const { t } = useTranslation();

    return (
        <div className="productDescriptionContainer">
            <>{t('productDescription.title')}</>
            <p>{t('productDescription.description')}</p>
        </div>
    );
};

export default ProductDescription;
