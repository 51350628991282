import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, FormControl, Select, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchMakes, fetchModels } from '../utils/carInfo';
import './CarSearchComponent.css';


const CarSearchComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [make, setMake] = useState(localStorage.getItem('selectedMake') || 'Any');
    const [model, setModel] = useState('');
    const [modelName, setModelName] = useState('');
    const [makeId, setMakeId] = useState('');
    const [isMakeChoosen, setIsMakeChoosen] = useState(false);

    useEffect(() => {
        const fetchMakesData = async () => {
            const makesData = await fetchMakes();
            setMakes(makesData);
        };

        fetchMakesData();
    }, []);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [window.location.hash]);

    useEffect(() => {
        if (make !== 'Any') {
            const fetchModelsData = async () => {
                const modelsData = await fetchModels(make);
                setModels(modelsData);
            };

            fetchModelsData();
        } else {
            setModels([]);
        }
    }, [make]);

    useEffect(() => {
        if (make !== 'Any') {
            localStorage.setItem('selectedMake', make);
        }
    }, [make]);

    const handleMakeChange = (e) => {
        setMake(e.target.value);
        setModel('Any');
        setIsMakeChoosen(true);
        const selectedMake = makes.find((m) => m.name === e.target.value);
        setMakeId(selectedMake ? selectedMake.id : '');
        localStorage.setItem('selectedMake', e.target.value);
    };

    const handleModelChange = (e) => {
        setModel(e.target.value);
        const selectedModel = models.find(m => m.i === e.target.value);
        setModelName(selectedModel ? selectedModel.n : '');
    };


    const handleAutoScout24Search = (e) => {
        e.preventDefault();
        const page = 1;
        const searchParams = {
            make,
            modelName,
            page
        };
        navigate(`/search-results?${new URLSearchParams(searchParams).toString()}`);
    };

    const topMakes = ['BMW', 'Volkswagen', 'Audi', 'Mercedes-Benz'];

    return (
        <Box className="car-search-section" id="search">
            <div className="car-search-component-title">
                {t('carSearch.title')}
            </div>
            <p className="car-search-component-subTitle">
                {t('carSearch.subTitle')}
            </p>

            <form className="car-search-form">
                <Grid container spacing={2} style={{ width: "90%", margin: 'auto' }}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth margin="normal" size="small">
                            <div className="car-search-form-question">{t('carSearch.make')}</div>
                            <Select
                                value={make}
                                onChange={handleMakeChange}
                            >
                                <MenuItem disabled>{t('carSearch.topMakes')}</MenuItem>
                                {makes.filter(make => topMakes.includes(make.name)).map(make => (
                                    <MenuItem key={make.id} value={make.name}>
                                        {make.name}
                                    </MenuItem>
                                ))}
                                <MenuItem disabled>{t('carSearch.allMakes')}</MenuItem>
                                {makes.map(make => (
                                    <MenuItem key={make.id} value={make.name}>
                                        {make.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ width: "90%", margin: 'auto' }}>
                    <Grid item xs={12} sm={12} style={{ paddingTop: "0" }}  >
                        <FormControl fullWidth margin="normal" size="small" >
                            <div className="car-search-form-question">{t('carSearch.model')}</div>
                            <Select
                                value={model}
                                onChange={handleModelChange}
                            >
                                {models.map(model => (
                                    <MenuItem key={model.i} value={model.i}>
                                        {model.n}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '1rem', width: "80%", margin: 'auto' }}>
                    <Grid item xs={12} sm={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleAutoScout24Search}
                            style={{ background: "#f37121", color: "#fff" }}
                        >
                            {t('carSearch.search')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box >
    );
};

export default CarSearchComponent;
