import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingNotFound from '../components/ListingNotFound';

import { ReactComponent as RoadIcon } from '../icons/road.svg';
import { ReactComponent as GearBoxIcon } from '../icons/gearbox.svg';
import { ReactComponent as FuelDispenser } from '../icons/fuel-dispenser.svg';
import { ReactComponent as CarIcon } from '../icons/car.svg';
import { ReactComponent as GearIcon } from '../icons/gear.svg';
import { ReactComponent as Power } from '../icons/power.svg';
import { ReactComponent as BackIcon } from '../icons/back.svg';

import ImageGallery from 'react-image-gallery';
import './VehicleDetailsPage.css';
import 'react-image-gallery/styles/css/image-gallery.css';


const CarDetails = ({ vehicleData }) => {
    const { t } = useTranslation();

    return (
        <div className="vehicle-details-car-details">
            <div className="vehicle-details-detail-item">
                <div className="vehicle-details-icon"><RoadIcon /></div>
                <div className="vehicle-details-text">
                    <div className="vehicle-details-label">{t('vehicleDetailsPage.mileage')}</div>
                    <div className="vehicle-details-value">{vehicleData.mileage}</div>
                </div>
            </div>
            <div className="vehicle-details-detail-item">
                <span className="vehicle-details-icon"><GearBoxIcon /></span>
                <div className="vehicle-details-text">
                    <div className="vehicle-details-label">{t('vehicleDetailsPage.gearbox')}</div>
                    <div className="vehicle-details-value">{vehicleData.gearbox}</div>
                </div>
            </div>
            <div className="vehicle-details-detail-item">
                <span className="vehicle-details-icon"><FuelDispenser /></span>
                <div className="vehicle-details-text">
                    <div className="vehicle-details-label">{t('vehicleDetailsPage.fuelType')}</div>
                    <div className="vehicle-details-value">{vehicleData.fuelType}</div>
                </div>
            </div>
            <div className="vehicle-details-detail-item">
                <span className="vehicle-details-icon"><Power /></span>
                <div className="vehicle-details-text">
                    <div className="vehicle-details-label">{t('vehicleDetailsPage.power')}</div>
                    <div className="vehicle-details-value">{vehicleData.power}</div>
                </div>
            </div>
        </div>
    );
};


const PriceDetails = ({ price }) => {
    const { t } = useTranslation();
    const truncatedPrice = price?.replace(".-1", "");
    const valuePrice = parseInt(truncatedPrice?.replace("€ ", "").replace(",", ""));
    const vat = Math.ceil(((valuePrice * 0.19) / 100) * 100);
    const customs = Math.ceil(((valuePrice * 0.18) / 100) * 100);
    const shipping = 1500;
    const netPrice = (valuePrice - vat) + customs + shipping;

    const formatNumber = (num) => new Intl.NumberFormat().format(Math.ceil(num / 100) * 100);

    return (
        <Box p={2} border={1} borderColor="grey.300" borderRadius={2} bgcolor="grey.100">
            <Typography variant="h6" component="div" gutterBottom>
                {t('vehicleDetailsPage.priceDetails')}
            </Typography>
            <Divider />
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography>{t('vehicleDetailsPage.basePrice')}:</Typography>
                <Typography variant="body1" component="span" color="primary">{`€ ${formatNumber(valuePrice)}`}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography>{t('vehicleDetailsPage.vat')}:</Typography>
                <Typography variant="body1" component="span" color="green">{`- € ${formatNumber(vat)}`}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography>{t('vehicleDetailsPage.customs')}:</Typography>
                <Typography variant="body1" component="span" color="success">{`+ € ${formatNumber(customs)}`}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" my={1}>
                <Typography>{t('vehicleDetailsPage.shipping')}:</Typography>
                <Typography variant="body1" component="span" color="success">{`+ € ${formatNumber(shipping)}`}</Typography>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="space-between" my={2}>
                <Typography variant="h6">{t('vehicleDetailsPage.finalPrice')}:</Typography>
                <Typography variant="h6" component="span" color="primary">{`€ ${formatNumber(netPrice)}`}</Typography>
            </Box>
        </Box>
    );
}


const VehicleDetailsPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const url = params.get('carUrl');
    const [vehicleData, setVehicleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    useEffect(() => {
        if (!url) {
            setError('No vehicle URL provided');
            return;
        }

        const fetchVehicleDetails = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/details?url=${encodeURIComponent(url)}`);
                if (!response.ok) {
                    throw new Error(`Error fetching vehicle details: ${response.statusText}`);
                }
                const data = await response.json();
                setVehicleData(data.listingDetails);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchVehicleDetails();
    }, [url, apiUrl]);

    if (loading) {
        return (
            <Box className="loading-spinner" display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="2rem">
                <CircularProgress />
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    {t('vehicleDetailsPage.loadingMessage')}
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <ListingNotFound />
        );
    }

    if (!vehicleData) {
        return <ListingNotFound />;
    }

    const images = vehicleData.images.map((img) => {
        return {
            original: img.replace(/(\.[a-zA-Z0-9]+)\/[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/, '$1'),
            thumbnail: img,
        }
    });

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="vehicle-details-container">
                <div className="vehicle-details-nav" onClick={handleBackClick}>
                    <BackIcon /> <span style={{ "cursor": "pointer" }}> {t('vehicleDetailsPage.backToVehicleList')} </span>
                </div>
                <div className="vehicle-details-main-content">
                    <div className="vehicle-details-header">
                        <div className="vehicle-details-image-section">
                            <ImageGallery
                                showBullets={false}
                                showIndex={false}
                                showThumbnails={true}
                                lazyLoad={false}
                                showPlayButton={false}
                                showNav={true}
                                showFullscreenButton={true}
                                items={images}
                            />
                            <div className="vehicle-details-desktop-only">
                                <CarDetails vehicleData={vehicleData} />
                            </div>
                        </div>
                        <div className="vehicle-details-info-section">
                            <div className="vehicle-details-title">
                                {vehicleData.title}
                            </div>
                            <div className="vehicle-details-model">
                                {vehicleData.model}
                            </div>
                            <div className="vehicle-details-price">
                                <PriceDetails price={vehicleData.price} />
                            </div>
                        </div>
                    </div>
                    <div className="vehicle-details-mobile-only">
                        <CarDetails vehicleData={vehicleData} />
                    </div>
                    <div className="vehicle-details-basic-details">
                        <div className="vehicle-details-basic-details-title">
                            <CarIcon /> {t('vehicleDetailsPage.basicData')}</div>
                        <div className="vehicle-details-basic-details-section">
                            <div className="vehicle-details-basic-details-row">
                                Body Type <p>{vehicleData.bodyType}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Seats <p>{vehicleData.seats}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Doors <p>{vehicleData.doors}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Model Code <p>{vehicleData.modelCode}</p>
                            </div>
                        </div>
                    </div>
                    <div className="vehicle-details-basic-details">
                        <div className="vehicle-details-basic-details-title">
                            <GearIcon /> {t('vehicleDetailsPage.technicalSpecifications')}
                        </div>
                        <div className="vehicle-details-basic-details-section">
                            <div className="vehicle-details-basic-details-row">
                                Power <p>{vehicleData.power}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Gearbox <p>{vehicleData.gearbox}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Engine Size <p>{vehicleData.engineSize}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Cylinders <p>{vehicleData.cylinders}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Empty Weight <p>{vehicleData.emptyWeight}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Emission Class <p>{vehicleData.emissionClass}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                Emissions Sticker <p>{vehicleData.emissionsSticker}</p>
                            </div>
                            <div className="vehicle-details-basic-details-row">
                                CO₂ Emissions <p>{vehicleData.co2Emissions}</p>
                            </div>
                        </div>
                    </div>
                    <div className="vehicle-details-basic-details">
                        {vehicleData.equipment && (
                            <>
                                <Typography variant="h6">{t('vehicleDetailsPage.comfortConvenience')}:</Typography>
                                <ul>
                                    {vehicleData.equipment.comfortConvenience.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <Typography variant="h6">{t('vehicleDetailsPage.entertainmentMedia')}:</Typography>
                                <ul>
                                    {vehicleData.equipment.entertainmentMedia.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <Typography variant="h6">{t('vehicleDetailsPage.safetySecurity')}:</Typography>
                                <ul>
                                    {vehicleData.equipment.safetySecurity.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                                <Typography variant="h6">{t('vehicleDetailsPage.extras')}:</Typography>
                                <ul>
                                    {vehicleData.equipment.extras.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleDetailsPage;
