import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropDownIcon } from '../icons/icon-blue-chevron-down.svg';
import './FAQSection.css';

const FAQSection = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqItems = [
        {
            question: t('faq.items.question1.question'),
            answer: t('faq.items.question1.answer'),
        },
        {
            question: t('faq.items.question2.question'),
            answer: t('faq.items.question2.answer'),
        },
        {
            question: t('faq.items.question3.question'),
            answer: t('faq.items.question3.answer'),
        },
        {
            question: t('faq.items.question4.question'),
            answer: t('faq.items.question4.answer'),
        },
        {
            question: t('faq.items.question5.question'),
            answer: t('faq.items.question5.answer'),
        },
        {
            question: t('faq.items.question6.question'),
            answer: t('faq.items.question6.answer'),
        },
        {
            question: t('faq.items.question7.question'),
            answer: t('faq.items.question7.answer'),
        },
        {
            question: t('faq.items.question8.question'),
            answer: t('faq.items.question8.answer'),
        },
        {
            question: t('faq.items.question9.question'),
            answer: t('faq.items.question9.answer'),
        },
        {
            question: t('faq.items.question10.question'),
            answer: t('faq.items.question10.answer'),
        },
        {
            question: t('faq.items.question11.question'),
            answer: t('faq.items.question11.answer'),
        },
        {
            question: t('faq.items.question12.question'),
            answer: t('faq.items.question12.answer'),
        },
    ];

    return (
        <div className="faq-container">
            <div id="faq" className="faq-section">
                <div className="title">
                    {t('faq.title')}
                </div>
                {faqItems.map((item, index) => (
                    <div key={index} className="faq-accordion">
                        <div
                            className="faq-accordion-summary"
                            onClick={() => toggleAccordion(index)}
                        >
                            <span className="question">{item.question}</span>
                            <span className={`dropdown-icon ${activeIndex === index ? 'open' : ''}`}>
                                <DropDownIcon />
                            </span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-accordion-details">
                                <p className="answer">{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
